import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
} from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import LinkButton from 'components/atoms/LinkButton';
import { addToast } from 'components/organisms/Toasts';
import ChannelsWithDrawer from 'components/rules/guidelines/ChannelsWithDrawer';
import GuidelinesContext from 'components/rules/guidelines/GuidelinesContext';
import { guidelinesUrl } from 'utilities/routes';
import {
  splitSnakeCase,
  titleize,
} from 'utilities/string';
import styles from './GuidelineDetail.module.css';

const guidelineDetailEligibilityType = PropTypes.shape({
  audit: PropTypes.shape({
    brands: PropTypes.arrayOf(PropTypes.string),
    markets: PropTypes.arrayOf(PropTypes.string),
  }),
  auditAsset: PropTypes.shape({
    creativeType: PropTypes.string,
  }),
  auditPost: PropTypes.shape({
    adFormats: PropTypes.arrayOf(PropTypes.string),
    campaignObjectives: PropTypes.arrayOf(PropTypes.string),
    contentTypes: PropTypes.string,
    placements: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
      placement: PropTypes.string,
      platform: PropTypes.string,
      publisher: PropTypes.string,
    })])),
    publishers: PropTypes.arrayOf(PropTypes.string),
  }),
});

const channelType = PropTypes.shape({
  id: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
});

export const guidelineDetailType = PropTypes.shape({
  channels: PropTypes.arrayOf(channelType).isRequired,
  description: PropTypes.string.isRequired,
  eligibility: guidelineDetailEligibilityType.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
});

const propTypes = {
  guidelineDetail: guidelineDetailType.isRequired,
  openDrawer: PropTypes.func.isRequired,
};

function GuidelineDetail({
  guidelineDetail: {
    name,
    channels,
    description,
    eligibility: {
      audit: {
        brands,
        markets,
      },
      auditPost: {
        adFormats,
        campaignObjectives,
        placements,
        publishers,
        contentTypes,
      },
      auditAsset: { creativeType },
    },
    id,
  },
  openDrawer,
}) {
  const {
    channelEligibilityMap,
    allBrands,
    allMarkets,
    allAdFormats,
    allContentTypes,
  } = useContext(GuidelinesContext);

  const toDisplayValue = (s) => titleize(splitSnakeCase(s));
  const placementToDisplayValue = (placement) => {
    if (placement === 'All') {
      return 'All';
    }

    return titleize(
      `${splitSnakeCase(placement.publisher)} - ${splitSnakeCase(placement.placement)}`,
    );
  };

  // creativeType is not defined for value "All" in the admin UI
  const creativeTypes = [creativeType || 'All'];

  const renderChannelDrawerSegment = (
    channel,
    defaultChannelValues,
    toDisplayValueFn,
    drawerCategory,
  ) => {
    if (!defaultChannelValues?.length) {
      return null;
    }

    const displayValues = defaultChannelValues.map((value) => toDisplayValueFn(value));

    return (
      <div key={`${drawerCategory}_${channel.value}`} className="u-marginBottom-16">
        <div className="u-marginBottom-8 u-flexRow u-flexAlignCenter">
          <ChannelLogo channel={channel.value} showPaid={false} size="size-24" />
          { channel.label }
        </div>
        <Divider />
        { displayValues.map((displayValue) => (
          <div
            key={displayValue}
            className="u-marginTop-8 u-marginBottom-8"
          >
            { displayValue }
          </div>
        )) }
      </div>
    );
  };

  const renderDefinitionItem = (
    header,
    values,
    defaultValues = {},
    toDisplayValueFn = toDisplayValue,
  ) => {
    let caption = null;
    const resolvedValues = values?.map(toDisplayValueFn);
    let drawerContents = null;

    if (!resolvedValues || resolvedValues.length === 0) {
      caption = 'N/A';
    } else if (resolvedValues.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      caption = resolvedValues[0];

      if (caption === 'All') {
        if (Array.isArray(defaultValues)) {
          // Show relevant data
          drawerContents = defaultValues.map((value) => <div key={value} className="u-marginBottom-8">{ value }</div>);
        } else if (channels.some((channel) => defaultValues[channel.value]?.length > 0)) {
          // Show relevant data segmented by channel.
          drawerContents = channels.map((channel) => renderChannelDrawerSegment(
            channel,
            defaultValues[channel.value],
            toDisplayValueFn,
            header,
          ));
        } else {
          // No relevant items exist for the channels. Display "N/A" instead of "All"
          caption = 'N/A';
        }
      }
    } else {
      // For standard lists of values, simply render them
      caption = resolvedValues.length;
      drawerContents = resolvedValues.map((value) => <div key={value} className="u-marginBottom-8">{ value }</div>);
    }

    return (
      <div className={styles.definitionItem}>
        <div className="t-caption-1">{ header }</div>
        <div className={styles.definitionItemValue}>
          { drawerContents ? (
            <LinkButton onClick={() => openDrawer(`${header} (${caption})`, drawerContents)}>
              { caption }
            </LinkButton>
          ) : caption }
        </div>
      </div>
    );
  };

  const copyDefinitionUrl = () => {
    navigator.clipboard.writeText(guidelinesUrl({ definition: id }));
    addToast('Link Copied to Clipboard');
  };

  return (
    <>
      <div className="u-flexRow">
        <div className="u-marginTop-8 u-flexGrow">
          { name && <div className="t-subtitle u-marginBottom-8">{ name }</div> }
          <div className="u-flexRow u-flexWrap">
            <ChannelsWithDrawer channels={channels} openDrawer={openDrawer} shownMax={2} />
            {
              renderDefinitionItem(
                'Placements:',
                placements,
                channelEligibilityMap.placements,
                placementToDisplayValue,
              )
            }
            {
              renderDefinitionItem(
                'Content Types:',
                contentTypes,
                allContentTypes,
              )
            }
            {
              renderDefinitionItem(
                'Campaign Objectives:',
                campaignObjectives,
                channelEligibilityMap.campaign_objectives,
              )
            }
            {
              renderDefinitionItem(
                'Publishers:',
                publishers,
                channelEligibilityMap.publishers,
              )
            }
            {
              renderDefinitionItem(
                'Creative Type:',
                creativeTypes,
                ['Image', 'Video'],
              )
            }
            {
              renderDefinitionItem(
                'Brands:',
                brands,
                allBrands,
              )
            }
            {
              renderDefinitionItem(
                'Markets:',
                markets,
                allMarkets,
              )
            }
            {
              renderDefinitionItem(
                'Ad Formats:',
                adFormats,
                allAdFormats,
              )
            }
          </div>
          <div>
            <div className="t-caption-1">Definition:</div>
            <div>{ description }</div>
          </div>
        </div>
        <div>
          <Button
            iconLeft="link"
            variant="round"
            onClick={copyDefinitionUrl}
          />
        </div>
      </div>
      <Divider margin />
    </>
  );
}

GuidelineDetail.propTypes = propTypes;

export default GuidelineDetail;
