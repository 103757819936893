import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  MessageModal,
  MoreButton,
  Search,
} from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import EditReportDetailsModal from 'components/reporting/EditReportDetailsModal';
import { typeLabels } from 'components/reporting/shared';
import { getItemSortBy } from 'utilities/item';
import { setItemsElement } from 'utilities/itemElement';
import { searchItems } from 'utilities/itemSearch';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';
import {
  destroy,
  patch,
  post,
} from 'utilities/requests';
import {
  duplicateReportingReportPath,
  editReportingReportPath,
  reportingReportPath,
  selectTypeReportingReportsPath,
} from 'utilities/routes';
import { toDate } from 'utilities/string';
import styles from './MyReports.module.css';

const PER_PAGE_LIMIT = 20;

const propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      updatedAt: PropTypes.number.isRequired,
      uuid: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const emptyState = (
  <div className={classNames('u-flexColumn', 'u-alignCenter', 't-empty', styles.empty)}>
    No reports found
  </div>
);

const headers = [
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'type',
    label: 'Report Type',
  },
  {
    key: 'createdAt',
    label: 'Date Created',
  },
  {
    key: 'updatedAt',
    label: 'Last Edited',
  },
  {
    key: 'more',
    label: '',
    sortable: false,
  },
];
const searchKeys = ['title', 'application', 'createdAt', 'updatedAt'];

async function deleteReport({ uuid }) {
  const response = await destroy(reportingReportPath(uuid));

  if (!response.isError) return response.data;

  return false;
}

async function duplicateReport({ uuid }) {
  const response = await post(duplicateReportingReportPath(uuid));

  if (!response.isError) return response.data;

  return false;
}

async function updateReport({
  uuid,
  description,
  title,
}) {
  const response = await patch(reportingReportPath(uuid), {
    title,
    description,
  });

  if (!response.isError) return response.data;

  return false;
}

function getDateDimension(timeMs) {
  const label = toDate(new Date(timeMs));

  return {
    label,
    value: timeMs,
  };
}

function getItem(report, onClickEditName, onClickDelete, onClickDuplicate) {
  const {
    createdAt,
    title,
    reportType,
    updatedAt,
    uuid,
  } = report;
  const moreDots = (
    <MoreButton
      menuSize="small"
      options={[{
        label: 'Edit Name',
        onClick: () => onClickEditName(report),
      }, {
        label: 'Duplicate',
        onClick: () => onClickDuplicate(report),
      }, {
        label: 'Delete',
        onClick: () => onClickDelete(report),
      }]}
    />
  );

  return {
    createdAt: getDateDimension(createdAt),
    id: { value: uuid },
    title: {
      display: { url: editReportingReportPath(uuid) },
      label: title,
      value: title.toLowerCase(),
    },
    type: { value: typeLabels[reportType] ?? 'N/A' },
    updatedAt: getDateDimension(updatedAt),
    more: {
      element: moreDots,
      value: '',
    },
  };
}

function getItems(reports, onClickEditName, onClickDelete, onClickDuplicate) {
  return reports.map((report) => getItem(report, onClickEditName, onClickDelete, onClickDuplicate));
}

function MyReports({ reports }) {
  const [items, setItems] = useState([]);
  const [searchedItems, setSearchedItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    key: 'updatedAt',
    asc: false,
  });
  const [page, setPage] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  useViewPage();

  const onClickDelete = (report) => {
    setSelectedReport(report);
    setDeleteModalOpen(true);
  };

  const onDelete = async () => {
    if (!selectedReport) return;

    const success = await deleteReport(selectedReport);

    if (success) {
      addToast('Report deleted');
      setItems((last) => last.filter(({ id }) => id.value !== selectedReport.uuid));
      track('delete_report', { uuid: selectedReport.uuid });
      setDeleteModalOpen(false);
    } else {
      addToast('Something went wrong. Please try again', {
        size: 'large',
        type: 'error',
      });
    }
  };

  const onClickEditName = (report) => {
    setSelectedReport(report);
    setEditModalOpen(true);
    track('edit_report_name_and_description', { uuid: report.uuid });
  };

  const onClickDuplicate = async (report) => {
    const result = await duplicateReport(report);

    if (result) {
      const item = getItem(result, onClickEditName, onClickDelete, onClickDuplicate);
      addToast('Report duplicated');
      setItems((last) => [...last, item]);
      track('duplicate_report', { uuid: report.uuid });
    } else {
      addToast('Something went wrong. Please try again', {
        size: 'large',
        type: 'error',
      });
    }
  };

  const saveReportEdit = async ({ description, title }) => {
    const editedReport = {
      ...selectedReport,
      description,
      title,
    };

    const editedItem = getItem(editedReport, onClickEditName, onClickDelete, onClickDuplicate);

    setItems((last) => last.map(
      (item) => (item.id.value === selectedReport.uuid ? editedItem : item),
    ));

    const reportUuid = await updateReport(editedReport);

    if (reportUuid) {
      addToast('Report Saved');
      track('save_report', {
        type: editedReport.reportType,
        uuid: reportUuid,
      });
    } else {
      addToast('Something went wrong, please try again', {
        size: 'large',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    setItems(getItems(reports, onClickEditName, onClickDelete, onClickDuplicate));
  }, [reports]);

  useEffect(() => {
    const searched = searchItems(items, search, searchKeys);
    setSearchedItems(setItemsElement(searched));
  }, [search, items]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedItems(searchedItems.slice().sort(byKeyDir));
  }, [sort, searchedItems]);

  return (
    <>
      <Card className={styles.content}>
        <div className="u-flexRow u-justifyEnd u-gap-24 u-marginBottom-16">
          <Search
            size="large"
            value={search}
            onChange={setSearch}
          />
          <AddNewButton
            label="Add New"
            url={selectTypeReportingReportsPath()}
            onClick={() => track('add_report')}
          />
        </div>
        <ItemsTable
          emptyTableContent={emptyState}
          headers={headers}
          items={sortedItems}
          maxWidthSize="custom"
          page={page}
          perPage={PER_PAGE_LIMIT}
          sort={sort}
          onPageChange={setPage}
          onSortChange={setSort}
        />
      </Card>
      <MessageModal
        actionButtonLabel="Delete"
        actionButtonVariant="destructive"
        closeButtonLabel="Cancel"
        isOpen={deleteModalOpen}
        title="Are you sure you want to delete this report?"
        onActionButtonClick={onDelete}
        onClose={() => setDeleteModalOpen(false)}
      >
        This action is permanent and cannot be undone.
      </MessageModal>
      <EditReportDetailsModal
        description={selectedReport?.description || ''}
        isOpen={editModalOpen}
        title={selectedReport?.title || ''}
        onClose={() => setEditModalOpen(false)}
        onReportEdit={saveReportEdit}
      />
    </>
  );
}

MyReports.propTypes = propTypes;

export default MyReports;
